(function () {
    /**
     * Validate group of checkboxes
     * when at least one must be checked
     *
     * @param checkboxContainer
     * @returns {null}
     * @constructor
     */
    Tollwerk.MulticheckboxRequiredValidator = function (checkboxContainer) {
        // Get required DOM elements
        this.container = checkboxContainer;
        this.checkboxes = this.container ? checkboxContainer.querySelectorAll('input[type="checkbox"]') : [];
        this.enabled = false;

        // Check if validation should be enabled.
        // Add mutation observer for recognizing changes of the `data-multicheckbox-required` attribute,
        // allowing enabling/disabling of this validation by changing that attribute
        this.enableOrDisable();
        this.containerObserver = new MutationObserver(function (mutations) {
            this.enableOrDisable();
        }.bind(this));
        this.containerObserver.observe(this.container, {
            attributes: true,
            childList: false,
            characterData: true,
        });

        // Add event listener to checkboxes for validating them,
        // removing the `required`-attribute from the hidden radio button
        // when there is at least one checkbox checked
        this.checkboxes.forEach(checkbox => {
            checkbox.addEventListener('change', function () {
                this.validate();
            }.bind(this));
        });
    }

    /**
     * Enable or disable the validation
     * based on the 'data-multicheckbox-required'-attribute of this.container.
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.enableOrDisable = function () {
        if(this.container.getAttribute('data-multicheckbox-required') === 'true') {
            this.enable();
        } else {
            this.disable();
        }
    }

    /**
     * Enable validation
     * and run validate method
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.enable = function () {
        this.enabled = true;
        this.validate();
    }

    /**
     * Disable validation
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.disable = function () {
        this.enabled = false;
    }

    /**
     * Lock form submission
     * by adding `required`-attribute to hidden radiobutton
     *
     * @param {boolean} lock
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.lock = function () {
        let message = this.container.hasAttribute('data-multicheckbox-required-text') ? this.container.getAttribute('data-multicheckbox-required-text') : 'Pflichtfeld / Required field';
        this.checkboxes.forEach(checkbox => {
            checkbox.setCustomValidity(message);
        });
    }

    /**
     * Unlock form submission
     * by removing `required`-attribute from hidden radiobutton
     *
     * @param {boolean} lock
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.unlock = function () {
        this.checkboxes.forEach(checkbox => {
            checkbox.setCustomValidity('');
        });    }

    /**
     * Validate the checkboxes
     * and lock or unlock them accordingly
     *
     * @returns {boolean}   Return true if checkboxes are valid, false if not.
     */
    Tollwerk.MulticheckboxRequiredValidator.prototype.validate = function () {
        let checkedCheckboxes = this.container.querySelectorAll('input[type="checkbox"]:checked');
        if (checkedCheckboxes.length) {
            this.unlock();
            return true;
        } else {
            this.lock();
            return false;
        }
    }

})();

/**
 * Init MultiCheckboxRequiredValidator for every DOM element having the `data-multicheckbox-required`-attribute
 */
Tollwerk.Init.registerOnReady(function () {
    let multiCheckboxContainers = document.querySelectorAll('*[data-multicheckbox-required');
    multiCheckboxContainers.forEach(container => {
        new Tollwerk.MulticheckboxRequiredValidator(container);
    });
});

