// // init
// window.onload = function(){
//     detailsNavigation();
// };
//
// var detailsNavigation = function(){
//     alert('make something');
//
//     // indicate area
//     var facsimileHero = document.querySelector('.FacsimileHero'),
//         usp = document.querySelector('.USP'),
//         facsimileHighligth = document.querySelector('.FacsimileHighlight');
//
//     var facsimileHeroBounding = facsimileHero.bounding();
//
//     console.log(facsimileHeroBounding);
//
//
// // on click go to area
// // make it smooth?
// };

(function () {
    'use strict';

    // indicate area
    const facsimileHero = document.querySelector('.FacsimileHero');
    if (facsimileHero) {
        const usp = document.querySelector('.USP');
        const facsimileHighlight = document.querySelector('.FacsimileHighlight');
        const facsimileHeroBounding = facsimileHero.bounding();
        /*console.log(facsimileHeroBounding);*/
    }

    // on click go to area
    // make it smooth?

})();


