(function () {
    /**
     * Checks if a given form field like input[type="text"] is empty
     * or has value. Sets corresponding data-attributes.
     *
     * Also check if field did at least once receive focus.
     *
     * Check will be performed on load and whenever the value changes.
     *
     * @param field
     * @constructor
     */
    Tollwerk.FormFieldEmptyWatcher = function (field) {

        // Get required DOM elements,
        // return null if something is missing.
        this.field = field;
        if (!this.field) {
            return null;
        }

        // Set data-attribute for first focus
        this.field.setAttribute('data-was-focused', 'false');
        this.field.addEventListener('blur', function(event){
            event.currentTarget.setAttribute('data-was-focused', 'true');
        });

        // Add event listeners for value change
        this.field.addEventListener('change', this.checkEmpty.bind(this));
        this.field.addEventListener('keyup', this.checkEmpty.bind(this));

        // Perform initial checkEmpty()
        this.checkEmpty();
    }

    /**
     * Check if this.field is empty,
     * set corresponding data-attributes
     */
    Tollwerk.FormFieldEmptyWatcher.prototype.checkEmpty = function () {
        if (this.field.value) {
            this.field.setAttribute('data-empty', 'false');
        } else {
            this.field.setAttribute('data-empty', 'true');
        }
    }
})();

/**
 * Init Tollwerk.FormFieldEmptyWatcher
 * TODO: Add Mutation observer to entire document?
 */
Tollwerk.Init.registerOnReady(function () {
    let formFields = document.querySelectorAll('input, textarea, select');
    formFields.forEach(formField => {
        new Tollwerk.FormFieldEmptyWatcher(formField);
    });
});

